<template>
  <b-container fluid>
    <b-card>
      <b-table
        class="text-center"
        hover
        :items="items"
        :current-page="currentPage"
        :per-page="12"
        :fields="[
          { key: 'id', label: 'id'},
          { key: 'name', label: 'nome'},
          { key: 'email', label: 'email'},
          { key: 'is_revoked', label: 'Está Ativo?'},
          { key: 'created_at', label: 'Data da Criação'},
        ]"
        responsive
        @row-clicked="handleRowClick"
      >
        <template #cell(is_revoked)="data">
          <b-badge
            pill
            :variant="data.item.is_revoked ? 'danger' : 'success'"
          >
            {{ data.item.is_revoked ? "NÃO" : "SIM" }}
          </b-badge>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | date_hour }}
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="12"
        align="center"
        size="sm"
        class="mt-2"
      />
    </b-card>

    <b-modal
      id="modal-scrollable"
      ref="liquidated-modal"
      size="md"
      scrollable
      title="Editar Usuário"
      hide-footer
    >
      <b-form>
        <b-form-group
          label="Nome"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="requestEditUser.name"
          />
        </b-form-group>

        <b-form-group
          label="E-Mail"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="requestEditUser.email"
          />
        </b-form-group>

        <b-form-checkbox-group
          v-model="requestEditUser.permissions"
          stacked
          :options="permissionsOptions"
          switches
        />

        <b-row>
          <b-col md="6">
            <b-button
              style="width: 100%"
              variant="primary"
              @click="updateUser"
            >
              Salvar Alterações
            </b-button>
          </b-col>
          <b-col md="6">
            <b-button
              variant="danger"
              style="width: 100%"
              @click="revokeUser"
            >
              Inativar Usuário
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import {
    BButton,
    BBadge,
    BCard,
    BContainer,
    BCol,
    BRow,
    BTable,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BPagination,
} from 'bootstrap-vue';
import UserService from '@/services/osiris/UserService';

const permissionsOptions = [
    { value: 2, text: 'Cadastrar Usuários' },
    { value: 3, text: 'Cadastrar Estabelecimentos' },
    { value: 4, text: 'Criar Opt-Ins' },
    { value: 5, text: 'Atualizar Opt-Ins' },
    { value: 6, text: 'Consulta Opt-Ins' },
    { value: 7, text: 'Rejeitar Opt-Ins' },
    { value: 8, text: 'Criar Opt-Out' },
    { value: 9, text: 'Criar Averbações' },
    { value: 10, text: 'Atualizar Avebações' },
    { value: 11, text: 'Buscar Averbações' },
    { value: 12, text: 'Consulta Agenda' },
    { value: 13, text: 'Visualizar Estatísticas' },
    { value: 14, text: 'Visualizar Extrato' },
].sort((a, b) => a.text.localeCompare(b.text));

export default {
    components: {
        BButton,
        BBadge,
        BCard,
        BContainer,
        BCol,
        BRow,
        BTable,
        BForm,
        BFormGroup,
        BFormInput,
        BFormCheckboxGroup,
        BPagination,
    },

    data() {
        return {
            items: [],
            requestEditUser: {},
            permissionsOptions,
            currentPage: 1,
        };
    },

    created() {
        this.getAllUsers();
    },

    methods: {
        async getAllUsers() {
            this.isLoading(true);
            const { data, status } = await UserService.index();
            this.isLoading(false);

            if (status !== 200) {
                this.$router.push('/error-404');
            }

            this.items = data.data.sort((a, b) => a.name.localeCompare(b.name));
        },

        async updateUser() {
            const confirm = await this.confirmAnAction('Deseja realmente editar esse usuário?');
            if (!confirm) {
                return;
            }
            this.isLoading(true);
            this.$bvModal.hide('modal-scrollable');
            const { status } = await UserService.update(this.requestEditUser.id, this.requestEditUser);
            this.isLoading(false);

            if (status === 200) {
                this.modalSuccess('Usuário foi editado com sucesso');
                this.getAllUsers();
            } else { this.modalError('Erro ao editar usuário'); }
        },

        async revokeUser() {
            const confirm = await this.confirmAnAction('Deseja realmente desativar esse usuário?');
            if (!confirm) {
                return;
            }
            const { status } = await UserService.update(this.requestEditUser.id, { is_revoked: true });

            if (status === 200) {
                this.modalSuccess('Usuário foi desativado com sucesso');
            } else { this.modalError('Erro ao desativar usuário'); }
        },

        handleRowClick(item) {
            const t = JSON.stringify(item);
            this.requestEditUser = JSON.parse(t);
            this.$refs['liquidated-modal'].show();
        },
    },
};
</script>
